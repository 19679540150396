body {
  margin: 0;
  overflow-x: hidden;
  background-color: rgb(243, 245, 247) !important;
  /* background-image: url("../src/assets/AestheticsImg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

.body {
  margin-left: 18%;
  margin-top: 3.5%;
  /* margin-right: 10px; */
}

@media only screen and (max-width: 992px) {
  .body {
    margin-left: 34% !important;
    margin-top: 6.5% !important;
    /* margin-right: 10px; */
  }
}

@media only screen and (min-width: 1200px) {
  .body {
    margin-left: 18%;
    margin-top: 3.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .body {
    margin-left: 20%;
    margin-top: 3.8%;
  }
}

@media only screen and (max-width: 1300px) {
  .body {
    margin-left: 22%;
    margin-top: 4%;
  }
}

p {
  margin-top: 17px !important;
}

.sigBox {
  border: 1px solid black;
}

.content {
  display: flex;
  justify-content: center;
  /* center horizontally */
  align-items: center;
  /* center vertically */
}

.image {
  border: 1px solid black;
}

.header2 {
  position: fixed;
  top: 76px;
  min-width: 100% !important;
  z-index: 150;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 10px;
  margin-left: 2px !important;
}

.header1 {
  position: fixed;
  top: 76px;
  min-width: 78% !important;
  z-index: 150;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 10px;
  margin-left: 2px !important;
}

.disabled-row {
  opacity: 0.5;
  /* reduces the opacity to visually indicate the disabled state */
  pointer-events: none;
  /* prevents interactions with the row */
}

div.ex1 {
  overflow-y: scroll;
  height: 580px;
}

.textContainer {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-1mrufi {
  height: 550px;
}

.break-cell {
  table-layout: fixed;
  word-break: break-all;
}

.disabledColor {
  .Mui-disabled {
    -webkit-text-fill-color: black !important;
    color: black !important;
  }
}

.PhoneInputInput {
  /* margin-top: 21px; */
  height: 40px !important;
  border-width: 1px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.PhoneInputInput:focus,.PhoneInputInput:hover {
  outline: none;
  height: 40px;
  border-width: 2px;
  border-top: none;
  border-left: none;
  border-right: none;
}

@media print {
  .no-print {
    display: none;
  }
}