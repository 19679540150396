/* h2,
h5,
h6 {
    text-align: center;
} */

/* h5,
h6 {
    border-bottom: 1px solid #121212;
    padding-bottom: 5px;
} */

p,
li {
    margin: 10px 0;
}

form {
    margin: auto;
    padding: 20px;
    /* border: 2px solid black; */
    border-radius: 8px;
}

section {
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin-left: 20px;
    padding-left: 10px;
    text-indent: -10px;
}

ul li:before {
    color: #151313;
}

strong {
    color: #030303;
}

.divider {
    border-top: 1px solid #1e1a1a;
    margin: 20px 0;
}

.signaturePad {
    width: 600px;
    height: 150px;
    border: 1px solid #000;
    margin-left: 10px;
}

.form-container {
    position: relative; /* Ensures the button and content can be positioned properly */
    height: 90vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
}

.scrollable-content {
    flex: 1; /* Take up available space */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    overflow-x: hidden;
}