.side-nav {
  margin-top: 20px;
  /* padding: 0px 20px 0px 20px; */
}

.active-text {
  color: rgb(230, 231, 233);
  text-decoration: none;

  /* left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 10px 0px 10px 0px; */
  .display-icon {
    display: inline;
  }
}

.de-active-text {
  text-decoration: none;
  color: #2c2929;

  /* display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 10px 0px 10px 0px; */
  .display-icon {
    display: none;
  }
}

.side-nav-body {
  /* height: 100vh; */
  overflow-x: hidden;
  background-color: #4e73df;
}

.nav-content {
  font-family: 'Roboto Sans', sans-serif;
  font-weight: 600 !important;
}

a:hover {
  color: black !important;
}