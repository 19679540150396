.container {
  margin-top: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #3c3c3c;
}

.rbc-calendar {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.rbc-toolbar {
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}

.rbc-toolbar button {
  border: none;
  background-color: #007bff;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s;
}

.rbc-toolbar button:hover {
  background-color: #0056b3;
}

.rbc-event {
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.rbc-event-CONFIRMED {
  background-color: #28a745 !important;
}

.rbc-event-NOT_CONFIRMED {
  background-color: #ff0707 !important;
}

.rbc-event-ARRIVED {
  background-color: #ff00bf !important;
}

.rbc-event-CHECKOUT {
  background-color: #162dff !important;
}

.rbc-event-NO_SHOW {
  background-color: violet !important;
}

.rbc-event-CANCELLED {
  background-color: #525252 !important;
}

.rbc-time-view .rbc-time-header {
  background-color: #f7f7f7;
}

.rbc-time-header-cell {
  color: #007bff;
  font-weight: bold;
}

.modal-title {
  color: #007bff;
}

.rbc-time-slot {
  flex: auto !important;
  height: 80px !important;
}