.video-section {
    border: 2px solid black;
    border-radius: 15px;
    padding-bottom: 20px;
    width: 100%;
}

.video-content {
    position: relative;
    width: 100% !important;
    height: 85vh !important;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

#subscriber {
    width: 80% !important;
    height: 80vh !important;
}

.screen {
    display: flex;
    justify-content: end;
}

#publisher-element {
    position: absolute;
    width: 20% !important;
    height: 20vh !important;
    margin-right: 3%;
    margin-top: 3%;
    /* margin-left: auto;
    margin-right: auto; */
}


/* .video {
    height: 100%;
} */