.wd-table tr:nth-child(odd) {
    background-color: #98edb6b3; /* Light grey color */
}

/* Style for even rows */
.wd-table tr:nth-child(even) {
    background-color: #ffffff; /* White color */
}

.wd-sub-table tr:nth-child(odd) {
    background-color: #f1eb7077; /* Light grey color */
}

.wd-sub-table tr:nth-child(even) {
    background-color: #ffffff; /* White color */
}